import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';



class Navbar extends Component {

  render() {
    return (
      <nav className="navbar navbar-dark fixed-top bg-dark flex-md-nowrap shadow mb-5">

<div className="container">

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='50'
                height='50'
                src={makeBlockie(this.props.account, 50).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='50'
                height='50'
                src={`data:image/png;base64,${new Identicon(this.props.account, 50).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<small className="text-white">Account: <span id="account">{this.props.account}</span></small>

            <a className="navbar-brand" href="https://github.com/worldlaw">Prepay.eth Escrow</a>
        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
      <div className="collapse navbar-collapse" id="navbarResponsive">
           <ul className="navbar-nav ml-auto">
          <li className="nav-item active">
            <a className="nav-link" href="#more-info">WorldLaw
          <span className="sr-only">(current)<small>.</small></span>
            </a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://github.com/worldlaw">Web3vm</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://github.com/worldlaw">Onelaw.us</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="https://github.com/worldlaw">WorldLaw</a>
          </li>
          </ul>
      </div>
</div>
  </nav>

);
}
}

export default Navbar;
