import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';


class Footer extends Component {

  render() {
    return (

<footer className="footer text-center bg-light">
      <div className="container">
      <div className="row">
      <div className="col-lg-10 h-100 text-center text-lg-center my-auto">
           <ul className="list-inline mb-2">
          <li className="list-inline-item">
            <a href="https://github.com/worldlaw">WorldLaw</a>
          </li>
          <li className="list-inline-item">&sdot;</li>
          <li className="list-inline-item">
            <a href="https://github.com/worldlaw">Users</a>
          </li>
          <li className="list-inline-item">&sdot;</li>
          <li className="list-inline-item">
            <a href="https://github.com/worldlaw">Bluefin</a>
          </li>
          <li className="list-inline-item">&sdot;</li>
          <li className="list-inline-item">
            <a href="https://github.com/worldlaw">Gumshoe</a>
          </li>
          <li className="list-inline-item">&sdot;</li>
          <li className="list-inline-item">
            <a href="https://github.com/worldlaw">Proofer</a>
          </li>
          <li className="list-inline-item">&sdot;</li>
          <li className="list-inline-item">
            <a href="https://github.com/worldlaw">Tidbits</a>
          </li>
          <li className="list-inline-item">&sdot;</li>
          <li className="list-inline-item">
            <a href="https://github.com/worldlaw">Judiciary</a>
          </li>
          </ul>

      </div>
      <div className="col-lg-10 h-100 text-center text-lg-center my-auto">
           <ul className="list-inline mb-0">
          <li className="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i className="fab fa-twitter-square fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i className="fab fa-github fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="wl-more-info-faq.html">
            <i className="fab fa-bitcoin fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i className="fab fa-ethereum fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i className="fas fa-gas-pump fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="wl-more-info-faq.html">
            <i className="fas fa-dice-d6 fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i className="fas fa-upload fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i className="fas fa-file-download fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="wl-more-info-faq.html">
            <i className="fas fa-user-tie fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i className="fas fa-shield-alt fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item mr-3">
            <a href="wl-more-info-faq.html">
            <i className="fas fa-landmark fa-2x fa-fw "></i>
            </a>
          </li>
          <li className="list-inline-item">
            <a href="wl-more-info-faq.html">
            <i className="fas fa-sliders-h fa-2x fa-fw "></i>
            </a>
          </li>
          </ul>
          </div>
      </div>
      <p className="m-0 text-center text-black">Copyright &copy; WorldLaw 2020 - WorldLaw.eth</p>
      </div>


<div className="d-flex justify-content-center mt-5">

{ this.props.account
? <img
className='ml-1 rounded-5'
width='24'
height='24'
src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
: <span></span>
}

{ this.props.account
? <img
className='ml-1 rounded-5'
width='24'
height='24'
src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
: <span></span>
}

<small className="text-black text-center">Current Account: <span id="account">{this.props.account}</span></small>
  
</div>

  </footer>

);
}
}

export default Footer;
