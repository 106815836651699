import React, { Component } from 'react'
import Web3 from 'web3'
import './App.css'
import { EVIDENCE_LOG_ABI, EVIDENCE_LOG_CONTRACT } from './ConfigEvidenceLog'
import EvidenceLog from './EvidenceLog' 

import Navbar from './Navbar'
import GutTop from './GutTop'
import GutBc from './GutBc'
// import Main from './Main'
import Gut1 from './Gut1'
import Gut2 from './Gut2'
import Gut3 from './Gut3'
import Gut4 from './Gut4'
import Gut5 from './Gut5'
import GutBot from './GutBot'
import Footer from './Footer'


class App extends Component {
  componentWillMount() {
    this.loadBlockchainData()
  }

  async loadBlockchainData() {
    const web3 = new Web3(Web3.givenProvider || "http://localhost:8545")
    const accounts = await web3.eth.getAccounts()
    this.setState({ account: accounts[0] })
    const evidenceLog = new web3.eth.Contract(EVIDENCE_LOG_ABI, EVIDENCE_LOG_CONTRACT)
    this.setState({ evidenceLog })
    const loggedArticleCount = await evidenceLog.methods.loggedArticleCount().call()
    this.setState({ loggedArticleCount })
    for (var i = 1; i <= loggedArticleCount; i++) {
      const loggedArticleOfEvidence = await evidenceLog.methods.loggedArticlesOfEvidence(i).call()
      this.setState({
        loggedArticlesOfEvidence: [...this.state.loggedArticlesOfEvidence, loggedArticleOfEvidence]
      })
    }
    this.setState({ loading: false })
  }

  constructor(props) {
    super(props)
    this.state = {
      account: '',
      loggedArticleCount: 0,
      loggedArticlesOfEvidence: [],
      loading: true
    }

    this.addEvidence = this.addEvidence.bind(this)
    this.toggleCompleted = this.toggleCompleted.bind(this)
  }

  addEvidence(evidenceDescription) {
    this.setState({ loading: true })
    this.state.evidenceLog.methods.addEvidence(evidenceDescription).send({ from: this.state.account })
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
    })
  }

  toggleCompleted(loggedArticleOfEvidenceId) {
    this.setState({ loading: true })
    this.state.evidenceLog.methods.toggleCompleted(loggedArticleOfEvidenceId).send({ from: this.state.account })
    .once('receipt', (receipt) => {
      this.setState({ loading: false })
    })
  }




render() {
  return (
  <div>
  <Navbar account={this.state.account} />
  {/* ---- */}
  <div><Gut1 account={this.state.account} />
  </div>
  {/* ---- */}
  <div><GutTop account={this.state.account} />
  </div>
  {/* ---- */}

  
  <div>
        <div className="container-fluid">
          <div className="row">
            <main role="main" className="col-lg-12 d-flex justify-content-center">
              { this.state.loading
                ? <div evidenceId="loader" className="text-center">
                  <p className="text-center text">Loading...</p>
                </div>
                : <EvidenceLog
                loggedArticlesOfEvidence={this.state.loggedArticlesOfEvidence}
                addEvidence={this.addEvidence}
                toggleCompleted={this.toggleCompleted} />
              }

            </main>
 
        <div className="col-sm-12 col-md-12 col-lg-12 mx-auto">
        
        <hr></hr>

      <p> Logged Evidence Count: {this.state.loggedArticleCount}</p>
      </div>

          </div>
        </div>
      </div>

  
   
  
  {/* ---- */}
  <div><GutBc account={this.state.account} />
  </div>
  {/* ---- */}
  <div><Gut3 account={this.state.account} />
  </div>
  {/* ---- */}
  <div><Gut2 account={this.state.account} />
  </div>
  {/* ---- */}
  <div><Gut4 account={this.state.account} />
  </div>
  {/* ---- */}
  <div><Gut5 account={this.state.account} />
  </div>
  {/* ---- */}
  <div><GutBot account={this.state.account} />
  </div>
  {/* ---- */}
  <div><Footer account={this.state.account} />
  </div>
  {/* ---- */}
  </div>
  /* ---- */
  );
  }
  }
  /* ---- */


export default App;