import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut4 extends Component {

  render() {
    return (

<div className="container">

<section className="call-to-action text-white text-center mb-5">
<div className="container">
<div className="row">
<div className="col-xl-9 mx-auto">

<div id="magic"></div>
</div>
<div className="col-md-10 col-lg-8 col-xl-7 mx-auto">
<form>
<div className="form-row">

<div className="col-12 col-md-3">
<button htref="#magic" className="btn btn-primary btn-md mb-3">Call LawDocs</button>
</div>
</div>

</form>
</div>
</div>
</div>


{ this.props.account
              ? <img
                className='ml-1 rounded-5 mr-1'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5 mr-2'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<small className="text-black">Gut4 Current Account: <span id="account">{this.props.account}</span></small>

</section>


</div>

);
}
}

export default Gut4;
