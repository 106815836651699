import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class GutTop extends Component {

render() {
return (

<div className="container">


</div>

);
}
}

export default GutTop;
