import React, { Component } from 'react';
// import Identicon from 'identicon.js';
// import makeBlockie from 'ethereum-blockies-base64';

class Gut2 extends Component {

render() {
return (

<div className="col-lg-9">

<div id="carouselExampleIndicators" className="carousel slide my-4" data-ride="carousel">
<ol className="carousel-indicators">
<li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
<li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
<li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
<li data-target="#carouselExampleIndicators" data-slide-to="3"></li>
<li data-target="#carouselExampleIndicators" data-slide-to="4"></li>
<li data-target="#carouselExampleIndicators" data-slide-to="5"></li>
<li data-target="#carouselExampleIndicators" data-slide-to="6"></li>
<li data-target="#carouselExampleIndicators" data-slide-to="7"></li>
</ol>
<div className="carousel-inner" role="listbox">
<div className="carousel-item active" data-interval="5000">
<img className="d-block img-fluid" src="../img/bg-masthead-00-lil.png" alt="WL First Slide"></img>
</div>
<div className="carousel-item" data-interval="3000">
<img className="d-block img-fluid" src="../img/bg-masthead-01-lil.png" alt="WL Second Slide"></img>
</div>
<div className="carousel-item" data-interval="3000">
<img className="d-block img-fluid" src="../img/bg-masthead-02-lil.png" alt="WL Third Slide"></img>
</div>
<div className="carousel-item" data-interval="3000">
<img className="d-block img-fluid" src="../img/bg-masthead-03-lil.png" alt="WL Fourth Slide"></img>
</div>
<div className="carousel-item" data-interval="3000">
<img className="d-block img-fluid" src="../img/bg-masthead-04-lil.png" alt="WL Fifth Slide"></img>
</div>
<div className="carousel-item" data-interval="3000">
<img className="d-block img-fluid" src="../img/bg-masthead-05-lil.png" alt="WL Sixth Slide"></img>
</div>
<div className="carousel-item" data-interval="3000">
<img className="d-block img-fluid" src="../img/bg-masthead-06-lil.png" alt="WL Seventh Slide"></img>
</div>
<div className="carousel-item" data-interval="3000">
<img className="d-block img-fluid" src="../img/bg-masthead-07-lil.png" alt="WL Eighth Slide"></img>
</div>
</div>
<a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="next">
<span className="carousel-control-prev-icon" aria-hidden="true"></span>
<span className="sr-only">LawDocs is a case precedent calling protocol under the WorldLaw</span>
</a>
<a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="prev">
<span className="carousel-control-next-icon" aria-hidden="true"></span>
</a>
</div>

</div>


);
}
}

export default Gut2;
