import React, { Component } from 'react'

class EvidenceLog extends Component {

  render() {
    return (

      <div className="container-fluid mt-5">
        <div className="row">
          <main role="main" className="col-sm-12 col-md-12 col-lg-12 ml-auto mr-auto" style={{ maxWidth: '800px' }}>

<div className="justify-content-center mt-2 text-danger"><small>Instant encryption tools:</small>
<div className="justify-content-center text-info"><small><a href="https://codebeautify.org/encrypt-decrypt" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="lock">&#128272;</span> AES-Rijndael CBC (cipher block chaining) encryption:</a></small>
<a href="https://tweetnacl.js.org/#/secretbox" target="_blank" rel="noopener noreferrer" ><br></br> <small><span role="img" aria-label="lock">&#128272;</span>  Secret-key authenticated encryption, Public-key authenticated encryption, Hashing, and Public-key signatures </small></a>
<a href="https://www.devglan.com/online-tools/aes-encryption-decryption" target="_blank" rel="noopener noreferrer" ><br></br><small><span role="img" aria-label="lock">&#128272;</span>  AES Encryption and Decryption Online Tool </small></a>
<hr></hr>
<a href="https://www.uscourts.gov/sites/default/files/2015-10-evidence-agenda_book_0.pdf" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="lock">&#128216;</span>Advisory Committee on Evidence Rules </a>
<hr></hr>

      </div>
        </div>



<div className="content mr-auto ml-auto mb-5">

        <form onSubmit={(event) => {
          event.preventDefault()
          this.props.addEvidence(this.loggedArticleOfEvidence.value)
        }}>



          <input
            id="newLoggedEvidence"
            ref={(input) => {
              this.loggedArticleOfEvidence = input
            }}
            type="text"
            className="form-control mb-2"
            placeholder="Add evidence article description or exhibit number. Consider encrypting."
            required />


<button type="submit" className="btn btn-primary btn-block" hidden={false}>Post Evidence Exhibit</button>

        </form>
</div>



        <ul id="loggedArticleOfEvidenceList" className="smtextred content mt-2" >
          <p class>Blue box denotes admitted into court record</p>

          { this.props.loggedArticlesOfEvidence.map((loggedArticleOfEvidence, key) => {
            return(

              <div className="content"  type="checkbox" key={key}>

                <label>
                  <input
                  type="checkbox"
                  name={loggedArticleOfEvidence.evidenceId}
                  defaultChecked={loggedArticleOfEvidence.isCompleted}
                  ref={(input) => {
                    this.checkbox = input
                  }}
                  onClick={(event) => {
                    this.props.toggleCompleted(this.checkbox.name) }}/>
                </label>

                <div className="content">
                <p class="smtextdarkgray">{loggedArticleOfEvidence.evidenceDescription}</p>
              </div>
            </div>
            )
          })}
        </ul>


        <ul id="isCompletedLogEvidenceList" className="list-unstyled">
        </ul>



      </main>
      </div>
      </div>

    );
  }
}

export default EvidenceLog;