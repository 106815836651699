import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut3 extends Component {

  render() {
    return (


      <div className="row">


<p className="bigtextcustom2"><strong>Prepay.eth Escrow is a Web3vm protocol</strong></p>
              <p className="textdesc">The protocol retrieves blocks and chunks of data for reassembly and decoding into a useable/viewable file. The decoded files include electronic documents with timestamps and in some cases, admissible direct testimony</p>
              <p>WorldLaw is a set of protocols that operate with public nodes, specifically Ethereum, Swarm and IPFS to store encrypted content and data-files for court use.</p>
      
              <br></br>

              <p className="bigtextcustom2"><strong>How does the Prepay.eth Escrow protocol fit in to the Web3vm system?</strong></p>
              <p className="textdesc">Court systems and alternative dispute resolution methods already exist and WorldLaw protocols are not designed to practice law or adjudicate claims. WorldLaw offers a system of smart-contracts that create indelible files on the public Ethereum, Swarm and IPFS node systems.</p>
              <p>This means a client’s file is safe without having a single point of failure. Evidence files are taken from their point of origin, usually from a mobile phone or desktop computer, and local encrypted on that same device. The WorldLaw protocols then take the encrypted evidence files and push them out to the node system. The WorldLaw system then provides a public record of all the locations of the encrypted files scattered throughout the node system. Once a recipient of one of potentially several decode keys is in possession of the locator file, they can access all the encrypted files, reassemble them, decode them, and then view the contents.</p>


      <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100">
            <a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/800-database.png" alt="database"></img></a>
      <div className="card-body">
          
          <h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="dotzero">&#128280;</span></a> 
          &nbsp; 
Create a Claim &nbsp;
            <a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" >see code</a>
          </h4>

        <h5><strong>Case Law Based Claims</strong></h5>
            <p className="card-text">Claims begin with filing a complaint. Complaints must state a claim for relief and based on some type of law. Drafting an effective, persuasive, and well-pleaded complaint is one of the most important steps in the litigation process.</p>
      </div>
      <div className="card-footer">
      <small className="text-muted"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" >FREE TO USE SERVICE &nbsp; 
      <span role="img" aria-label="lock">&#128159;</span>
      </a></small>
      </div>
      </div>
      </div>





      <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100">
            <a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/800-imagebase-g.png" alt="imagebase"></img></a>
      <div className="card-body">
          
          <h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="book">&#128216;</span></a> 
          &nbsp; 
Organize Element Based Evidence &nbsp;
            <a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" >see code</a>
          </h4>
        
          <h5><strong>WorldLaw Legal Efficiency Tools</strong></h5>
            <p className="card-text">Element is a one part of many in a legal claim that must be proved for the claim to succeed. Lawyers and legal aid providers have a good understanding in apply rules or laws and the elements to evidence of a claim. Contact a lawyer before you try to build an element legal claim using the WorldLaw protocols</p>
      </div>
      <div className="card-footer">
      <small className="text-muted"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" >FREE TO USE SERVICE &nbsp; 
      <span role="img" aria-label="lock">&#128159;</span>
      </a></small>
      </div>
      </div>
      </div>





      <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100">
            <a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/800-impthings-g.png" alt="imagebase"></img></a>
      <div className="card-body">

          <h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="scale">&#9878;</span></a> 
          &nbsp; 
Access California Jury Instructions &nbsp;
            <a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" >see code</a>
          </h4>

          <h5><strong>Jury Instructions For Judges and Attorneys</strong></h5>
            <p className="card-text">The Judicial Council of California has adopted jury instructions that accurately convey the law using language that is understandable to jurors. The Judicial Council of California owns the copyright in the Jury Instructions and seeks to make the instructions available to the public and hereby grants members of the public a nonexclusive license to reproduce or modify the jury instructions,except to any commercial publisher for purposes of reproducing the instructions (in any format) or publicly distributing them.</p>
      </div>
      <div className="card-footer">
      <small className="text-muted"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" >
        FREE TO USE SERVICE &nbsp; 
      <span role="img" aria-label="lock">&#128159;</span>
      </a></small>
      </div>
      </div>
      </div>





      <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100">
            <a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/800-record.png" alt="imagebase"></img></a>
      <div className="card-body">

          <h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="magnify">&#128269;</span></a> 
          &nbsp; 
Search Public Records &nbsp;
            <a href="https://github.com/worldlaw"  target="_blank" rel="noopener noreferrer">see code</a>
          </h4>

          <h5><strong>California Appellate Courts</strong></h5>
            <p className="card-text">The California Appellate Courts Case Information System provides case information for California Supreme Court and Court of Appeal cases. Case information is updated once an hour throughout the business day.</p>
      </div>
      <div className="card-footer">
      <small className="text-muted"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" >SMALL TX FEE REQUIRED &nbsp; 
      <span role="img" aria-label="lock">&#128279;</span>
      <span role="img" aria-label="lock">&#9981;</span>
      </a></small>
      </div>
      </div>
      </div>



      <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100">
            <a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/800-search-query.png" alt="search"></img></a>
      <div className="card-body">

          <h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="peeps">&#128101;</span></a>
          &nbsp; 
Use third-party verification service to validate the on-chain evidence records &nbsp;
            <a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" >see code</a>
          </h4>

          <h5><strong>A Proofer is a Verifying Subcontractor</strong></h5>
            <p className="card-text">Proofers manually checking the accuracy of the information provided from the WorldLaw users that are pursuing a legal claim. The verification of evidence can be provided by document like birth certificates, bank statements, business records, etc. WorldLaw provides flexible verification protocols that allow clients and agents/lawyers to define the evidence that requires verification and the means by which the evidence is to be verified.</p>
      </div>
      <div className="card-footer">
      <small className="text-muted"><a href="https://github.com/worldlaw">FEE REQUIRED 
      <span role="img" aria-label="lock">&#128272;</span>
      <span role="img" aria-label="lock">&#128273;</span>
      <span role="img" aria-label="lock">&#9981;</span>
      </a></small>
      </div>
      </div>
      </div>




      <div className="col-lg-4 col-md-6 mb-4">
      <div className="card h-100">
            <a href="https://github.com/worldlaw"  target="_blank" rel="noopener noreferrer" ><img className="card-img-top" src="../img/800-evidstore.png" alt="evidstore"></img></a>
      <div className="card-body">

          <h4 className="card-title font-weight-bold"><a href="https://github.com/worldlaw" target="_blank" rel="noopener noreferrer" ><span role="img" aria-label="folder">&#128194;</span></a> 
          &nbsp; 
Commence court action &nbsp;
            <a href="https://github.com/worldlaw"  target="_blank" rel="noopener noreferrer" > Go to top</a>
          </h4>

          <h5><strong>The California Rules of Court</strong></h5>
            <p className="card-text">Rule 1.51. California Law Enforcement Telecommunications System (CLETS) information form (a) Confidential CLETS Information form to be submitted to the court: A person requesting protective orders under Code of Civil Procedure section 527.6, 527.8, or 527.85; Family Code section 6320, 6404, or 6454; Penal Code sections 18100-18205; or Welfare and Institutions Code section 213.5 or 15657.03 must submit to the court with the request a completed Confidential CLETS Information form.</p>
      </div>
      <div className="card-footer">
      <small className="text-muted"><a href="https://github.com/worldlaw">FEE REQUIRED &nbsp; 
      <span role="img" aria-label="lock">&#128272;</span>
      <span role="img" aria-label="lock">&#128273;</span>
      <span role="img" aria-label="lock">&#9981;</span>
      </a></small>
      </div>
      </div>

      </div>


<div className="container">
<div className="row">

</div>


<div className="d-flex justify-content-center mt-5">

{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<small className="text-black">Gut3 Current Account: <span id="account">{this.props.account}</span></small>

</div>
</div>

</div>


);
}
}

export default Gut3;
