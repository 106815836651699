import React, { Component } from 'react';
import Identicon from 'identicon.js';
import makeBlockie from 'ethereum-blockies-base64';

class Gut1 extends Component {

  render() {
    return (

<div className="container">


<section className="call-to-action text-white text-center mb-5 mt-5">
<div className="container">
<div className="row">
<div className="col-xl-9 mx-auto">
<p className="bigtextdarkgray"><strong>Prepay.eth escrow is a Web3vm protocol</strong></p>
              <p className="textdesc">The protocol retrieves blocks and chunks of data for reassembly and decoding into a useable/viewable file. The decoded files include electronic documents with timestamps and in some cases, admissible direct testimony</p>
              <p>Prepay.eth escrow is a Web3vm protocol that operates with public nodes, and the Ethereum blockchain to store encrypted content and data-files for public recording and court use.</p>
      
              <br></br>

              <p className="bigtextdarkgray"><strong>How does the Prepay.eth escrow  protocol fit in to the Web3vm system?</strong></p>
              <p className="textdesc">Court systems and alternative dispute resolution methods already exist and WorldLaw protocols are not designed to practice law or adjudicate claims. WorldLaw offers a system of smart-contracts that create permanent files on the public Ethereum, and content node systems.</p>
              <p>This means a client’s file is safe without having a single point of failure. Electronic files are encrypted and copied from their point of origin, usually from a mobile phone or desktop computer.  The encryption may happen "locally" which means on the source device not a third party. Sometimes an outside source is used to encrypt the files. The Web3vm protocols take encrypted files and pushes them to the public blockchain and node system. This system then provides a public record of all the locations of the encrypted files scattered throughout the node system.</p>

</div>
</div>
</div>

</section>



<div className="d-flex justify-content-center mt-5">

{ this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={makeBlockie(this.props.account, 24).toString()} alt="AcctIconImage"/>
              : <span></span>
            }

            { this.props.account
              ? <img
                className='ml-1 rounded-5'
                width='24'
                height='24'
                src={`data:image/png;base64,${new Identicon(this.props.account, 24).toString()}`} alt="AcctIconImage"/>
              : <span></span>
            }

<div className="text-black">Current Account: <span id="account">{this.props.account}</span>

</div>
</div>


</div>

);
}
}
export default Gut1;
